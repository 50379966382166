body {
  background-color: #242424;
  font-family: "Inter", sans-serif;
  color: #cdcdcd;
}

.explore-btn {
  display: flex;
  align-items: center;
  padding: 12px 16px 12px 24px;
  border-radius: 1000px;
  border: 0.5px solid #fefefe;
  text-align: justify;
  font-size: 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin-top: 40px;
}
.explore-btn img {
  margin-left: 16px;
}

#map-header a.active {
  text-decoration: underline;
  font-weight: 700;
  color: #ffffff;
}

#default-sidebar a.active.darkTheme{
  --tw-bg-opacity: 1;
  background-color: #343434;
}

#default-sidebar a.active.lightTheme {
  --tw-bg-opacity: 1;
  background-color: #ffffff;
}

.image-layer-container {
    position: relative;
    width: 558px;
    height: 440px;
    overflow: hidden;
    border-radius: 80px;
}
.image-layer-item {
    position: absolute;
    top: 0;
    height: 100%;
    overflow: hidden;
}
.image-layer-item img {
    max-width: inherit;
}

.alalytics-icon {
    width: 50px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}